import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Alert,
} from '@mui/material';
import ProjectService from 'services/ProjectService';
import LinkedFilesService from 'services/LinkedFilesService';

export const LinkToContentModal = ({ open, onClose, fileId, projectId }) => {
  const [contents, setContents] = useState([]);
  const [selectedContent, setSelectedContent] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchContents = async () => {
      try {
        setIsLoading(true);
        setError('');
        const { data } = await ProjectService.getProjectAssets(projectId);
        setContents(data.data || []);
      } catch (e) {
        console.error('Error fetching project assets:', e);
        setError('Failed to load content list. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    if (open) {
      fetchContents();
      setSelectedContent(''); // Reset selection when modal opens
    }
  }, [open, projectId]);

  const handleSubmit = async () => {
    if (!selectedContent) return;

    setIsLoading(true);
    setError('');
    try {
      await LinkedFilesService.attachToContent({
        objectId: selectedContent,
        fileId,
      });
      onClose();
    } catch (e) {
      console.error('Error linking file to content:', e);
      setError('Failed to link file to content. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Link to Content</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Please select the content you would like to link this document to.
          </Typography>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          <FormControl fullWidth disabled={isLoading}>
            <InputLabel>Content</InputLabel>
            <Select value={selectedContent} onChange={(e) => setSelectedContent(e.target.value)} label="Content">
              {contents.map((content) => (
                <MenuItem key={content.id} value={content.id}>
                  {content.name || content.title}
                </MenuItem>
              ))}
              {contents.length === 0 && (
                <MenuItem disabled>{isLoading ? 'Loading...' : 'No content available'}</MenuItem>
              )}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isLoading}>
          Cancel
        </Button>
        <Button variant="contained" color="secondary" onClick={handleSubmit} disabled={!selectedContent || isLoading}>
          {isLoading ? 'Linking...' : 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

LinkToContentModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  fileId: PropTypes.number.isRequired,
  projectId: PropTypes.number.isRequired,
};
