import React, { useEffect, useState } from 'react';
import { IconButton, Slide, Box } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useStyles } from '../styles';

export const ImageViewer = ({ images }) => {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState(0);
  const [imgList, setImgList] = useState([]);

  const [slideDirection, setSlideDirection] = useState('left');

  useEffect(() => {
    // console.log('images', images);
    setImgList(images);
  }, [images]);

  const handleNextPage = () => {
    setSlideDirection('left');
    if (currentPage === imgList.length - 1) {
      setCurrentPage(0);
    } else {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    setSlideDirection('right');
    if (currentPage === 0) {
      setCurrentPage(imgList.length - 1);
    } else {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      <IconButton onClick={handlePrevPage} color="secondary" size="large">
        <NavigateBeforeIcon sx={{ fontSize: 35 }} />
      </IconButton>
      {imgList.map((img, index) => (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: currentPage === index ? 'block' : 'none',
          }}
        >
          <Slide direction={slideDirection} in={currentPage === index}>
            <img src={img ? img.imagePath : ''} alt="" className={classes.imageContent} />
          </Slide>
        </Box>
      ))}
      <IconButton onClick={handleNextPage} color="secondary" size="large">
        <NavigateNextIcon sx={{ fontSize: 35 }} />
      </IconButton>
    </Box>
  );
};
